.main {
  position: relative;
  background-image: url(./images/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 0;
  font-family: ProximaNovaBold;
}
.container {
  width: 81%;
  height: 75%;
  position: relative;
  display: flex;
  background: #ef5924;
  border-radius: 25px;
  background-image: url(./images/cont.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftside {
  max-width: 50%;
  margin-left: 3%;
}
.leftside_header {
  color: white;
  font-size: 50px;
}
.leftside_desc {
  font-size: 22px;
  color: white;
  line-height: 1.8rem;
  margin-top: 30px;
}
.leftside_button {
  width: 370px;
  height: 65px;
  border: 1px solid white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 40px;
  transition: 0.2s;
}
.leftside_button:hover {
  background-color: white;
  color: black;
  transition: 0.2s;
}
.rightside {
  width: 35%;
  height: 110%;
  background-image: url(./images/foxiko.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 77%;
  margin-left: 8%;
}

@media only screen and (max-width: 390px) {
  .leftside {
    zoom: 40%;
  }
}

@media only screen and (min-width: 1280px) {
  .leftside {
    zoom: 70%;
  }
}

@media only screen and (min-width: 1366px) {
  .leftside {
    zoom: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .leftside {
    zoom: 100%;
  }
}

@media only screen and (min-width: 2560px) {
  .leftside_button {
    zoom: 140%;
  }
}

@media only screen and (min-width: 3840px) {
  .leftside {
    zoom: 160%;
  }
}
