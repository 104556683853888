.main {
  position: relative;
  background-image: url(./images/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 0;
  font-family: ProximaNovaBold;
}
.container {
  width: 81%;
  height: 75%;
  margin-top: 5%;
  position: relative;
  display: flex;
}
.left {
  width: 750px;
  height: 450px;
  margin-top: 40px;
}
.right {
  /* background: rgba(255, 255, 0, 0.404); */
  width: 600px;
  height: 650px;
  margin-left: 200px;
  background-image: url(./images/skin.png);
  background-size: 99%;
  background-repeat: no-repeat;
  background-position: center;
}
.bottom {
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0px;
}
.bottom_desc {
  color: #f0f0f0;
  font-size: 24px;
}
.bottom_team {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.bottom_team_member {
  margin-right: 50px;
  text-align: center;
}
.bottom_team_member_name {
  color: #f0f0f0;
  font-size: 18px;
  margin-top: 10px;
}
.bottom_team_member_img1 {
  width: 90px;
  height: 90px;
  background-image: url(./images/gamesnemsis.png);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  margin: auto;
}
.bottom_team_member_img2 {
  width: 90px;
  height: 90px;
  background-image: url(./images/mike.png);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  margin: auto;
}
.bottom_team_member_img3 {
  width: 90px;
  height: 90px;
  background-image: url(./images/gio.png);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  margin: auto;
}
.bottom_designline {
  width: 2px;
  background: rgba(255, 255, 255, 0.342);
  margin-left: 10px;
  height: 110px;
}
.bottom_pocket {
  width: 290px;
  height: 60px;
  background-image: url(./images/pockethost.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 65px;
}
.left_main {
  color: #ffffff;
  font-size: 50px;
  max-width: 600px;
  line-height: 4rem;
}
.left_desc {
  margin-top: 20px;
  color: #f0a68b;
  font-size: 18px;
  line-height: 1.8rem;
}
.left_btn_cont {
  display: flex;
  margin-top: 50px;
}

.left_btn {
  background-color: #ef5924;
  width: 350px;
  height: 65px;
  box-shadow: -6px 6px 0 #a6421f;
  text-decoration: none;
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 19px;
  cursor: pointer;
  margin-left: 9px;
}

.left_btn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: #a6421f;
  transform: rotate(45deg);
  z-index: -1;
}

.left_btn::before {
  content: "";
  position: absolute;
  bottom: -4px;
  right: 2px;
  width: 8px;
  height: 8px;
  background-color: #a6421f;
  transform: rotate(45deg);
  z-index: -1;
}

.left_btn:hover {
  top: 3px;
  left: -3px;
  box-shadow: -3px 3px 0 #a6421f;
}
.left_btn:hover::after {
  top: 1px;
  left: -2px;
  width: 4px;
  height: 4px;
}
.left_btn:hover::before {
  bottom: -2px;
  right: 1px;
  width: 4px;
  height: 4px;
}

.left_online_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}
.left_online_icon {
  background-color: #2dff5e;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  box-shadow: 0px 1px 11px #2dff5e;
}
.left_online_text {
  color: white;
  font-size: 20px;
  margin-left: 15px;
}

@media only screen and (min-width: 390px) {
  .left {
    zoom: 50%;
  }
  .bottom {
    zoom: 38%;
  }
}

@media only screen and (min-width: 768px) {
  .bottom {
    zoom: 70%;
  }
}

@media only screen and (min-width: 1280px) {
  .left {
    zoom: 70%;
  }
  .right {
    zoom: 70%;
  }
  .bottom {
    zoom: 70%;
  }
}

@media only screen and (min-width: 1366px) {
  .left {
    zoom: 80%;
  }
  .right {
    zoom: 80%;
  }
  .bottom {
    zoom: 80%;
  }
}

@media only screen and (min-width: 1600px) {
  .left_online_text {
    font-size: 18px;
  }
  .left {
    zoom: 100%;
  }
  .right {
    zoom: 100%;
  }
  .bottom {
    zoom: 100%;
  }
}

@media only screen and (min-width: 1920px) {
  .left_online_text {
    font-size: 20px;
  }
}

@media only screen and (min-width: 2460px) {
  .left {
    zoom: 140%;
  }
  .right {
    zoom: 140%;
  }
  .bottom {
    zoom: 140%;
  }
}

@media only screen and (min-width: 3840px) {
  .left {
    zoom: 200%;
  }
  .right {
    zoom: 200%;
  }
  .bottom {
    zoom: 200%;
  }
}
