.main {
  position: relative;
  background-image: url(./images/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 0;
  font-family: ProximaNovaBold;
}
.wrapper {
  width: 81%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.frame {
  width: 100%;
  height: 85%;
  overflow: hidden;
  border-radius: 35px;
  margin-top: 40px;
}

.box-container {
  display: flex;
  height: 100%;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: 70vh;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img1 {
  background-image: url(./images/slider1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.img2 {
  background-image: url(./images/slider2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.img3 {
  background-image: url(./images/slider3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.img4 {
  background-image: url(./images/slider4.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.button-container {
  display: flex;
}

.button {
  background: #666;
  color: #fff;
  width: 50px;
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  user-select: none;
  cursor: pointer;
}

.button-2-container {
  display: flex;
}

.button-2 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #e0e0e0;
  margin: 6px;
  cursor: pointer;
}

.active {
  background: #999;
}

.box_arrows {
  width: 80px;
  height: 50px;
  top: 50px;
  position: absolute;
  right: 50px;
  display: flex;
}
.box_right {
  width: 28px;
  height: 40px;
  background-image: url(./images/right.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.2s;
  zoom: 80%;
}
.box_left {
  width: 28px;
  height: 40px;
  background-image: url(./images/left.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 40px;
  cursor: pointer;
  transition: 0.2s;
  zoom: 80%;
}

.box_left:hover {
  background-image: url(./images/left_hover.png);
  transition: 0.2s;
}
.box_right:hover {
  background-image: url(./images/right_hover.png);
  transition: 0.2s;
}
.box_date {
  height: 50px;
  position: absolute;
  left: 60px;
  top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_square {
  width: 21px;
  height: 21px;
  background: #ef5924;
}
.box_text {
  font-size: 19px;
  color: white;
  margin-left: 15px;
}
.box_main {
  width: 70%;
  position: absolute;
  left: 60px;
  bottom: 60px;
}
.box_main_header {
  font-size: 30px;
  color: white;
}
.box_main_desc {
  margin-top: 20px;
  font-size: 19px;
  color: white;
  line-height: 2rem;
}
.head {
  font-size: 30px;
  color: white;
  position: absolute;
  left: 0px;
  top: 0px;
}

@media only screen and (max-width: 390px) {
  .box_main_header {
    zoom: 60%;
  }
  .box_main_desc {
    zoom: 60%;
  }
  .head {
    zoom: 60%;
  }
  .box_arrows {
    zoom: 60%;
  }
}

@media only screen and (min-width: 1280px) {
  .box_main_header {
    zoom: 80%;
  }
  .box_main_desc {
    zoom: 80%;
  }
  .head {
    zoom: 80%;
  }
  .box_arrows {
    zoom: 80%;
  }
}

@media only screen and (min-width: 1366px) {
  .box_main_header {
    zoom: 90%;
  }
  .box_main_desc {
    zoom: 90%;
  }
  .head {
    zoom: 90%;
  }
  .box_arrows {
    zoom: 90%;
  }
}

@media only screen and (min-width: 1920px) {
  .box_main_header {
    zoom: 100%;
  }
  .box_main_desc {
    zoom: 100%;
  }
  .head {
    zoom: 100%;
  }
  .box_arrows {
    zoom: 100%;
  }
}

@media only screen and (min-width: 2560px) {
  .box_main_header {
    zoom: 140%;
  }
  .box_main_desc {
    zoom: 140%;
  }
  .head {
    zoom: 140%;
  }
  .box_date {
    zoom: 140%;
  }
  .box_arrows {
    zoom: 140%;
  }
}

@media only screen and (min-width: 3840px) {
  .box_main_header {
    zoom: 200%;
  }
  .box_main_desc {
    zoom: 200%;
  }
  .head {
    zoom: 200%;
  }
  .box_date {
    zoom: 200%;
  }
  .box_arrows {
    zoom: 200%;
  }
}
