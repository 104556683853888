.main {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 0;
    font-family: ProximaNovaBold;
    background-color: #000000;
  }
  a {
    text-decoration: none;
    display: block;
  }
  .container {
    width: 81%;
    height: 75%;
    position: relative;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .leftside {
    position: absolute;
    left: 0px;
  }
  .rightside {
    position: absolute;
    right: 0px;
  }
  .nemsis {
    color: #ef5924;
    font-size: 18px;
  }
  .leftside_header {
    font-size: 18px;
    color: white;
  }
  .leftside_digitalhub {
    font-size: 18px;
    color: #a4a4a4;
    margin-top: 12px;
  }
  .rightside {
    width: 285px;
    height: 32px;
    background-image: url(./images/pockethost.png);
    background-repeat: no-repeat;
    background-size: 98%;
    background-position: center;
  }
  
  @media only screen and (max-width: 390px) {
    .main {
      zoom: 40%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .main {
      zoom: 50%;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .main {
      zoom: 70%;
    }
  }
  
  @media only screen and (min-width: 1366px) {
    .main {
      zoom: 80%;
    }
  }
  @media only screen and (min-width: 1920px) {
    .main {
      zoom: 100%;
    }
  }
  
  @media only screen and (min-width: 3840px) {
    .main {
      zoom: 160%;
    }
  }
  