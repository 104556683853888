.main {
    width: 100%;
    height: 125px;
    display: flex;
    font-family: ProximaNovaBold;
    z-index: 1;
    position: absolute;
  }
  .container {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .left {
    width: 25%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 55px;
  }
  .middle {
    width: 50%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 25%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logobox {
    width: 250px;
    height: 58px;
    background-image: url(./images/nemsiscraft.png);
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .middle_cont {
    display: flex;
  }
  .item {
    margin-left: 60px;
    height: 125px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .item_text {
    font-size: 20px;
    width: 100%;
    height: 75px;
    margin-bottom: -50px;
    color: rgba(255, 255, 255, 0.555);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
  }
  .item_main_line {
    width: 120%;
    height: 15px;
    background-color: #ef5924;
    position: absolute;
    top: -11px;
    border-radius: 15px;
    box-shadow: 0px 4px 25px 5px #ef5a2491;
    opacity: 0;
    transition: 0.2s;
  }
  .fix {
    margin-left: 0px !important;
  }
  .item:hover .item_main_line {
    opacity: 1;
    transition: 0.2s;
  }
  .item_text:hover {
    color: white;
    transition: 0.2s;
  }
  .right_cont {
    display: flex;
  }
  .discord {
    width: 40px;
    height: 30px;
    background-image: url(./images/dc.png);
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.1s;
  }
  .youtube {
    width: 40px;
    height: 30px;
    background-image: url(./images/yt.png);
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 20px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.1s;
  }
  .mainp {
    position: absolute;
    width: 100px;
    height: 100px;
  }
  .youtube:hover {
    opacity: 1;
    transition: 0.1s;
  }
  .discord:hover {
    opacity: 1;
    transition: 0.1s;
  }
  
  @media only screen and (max-width: 390px) {
    .main {
      zoom: 40%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .main {
      zoom: 60%;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .main {
      zoom: 70%;
    }
  }
  
  @media only screen and (min-width: 1366px) {
    .main {
      zoom: 80%;
    }
  }
  
  @media only screen and (min-width: 1600px) {
    .main {
      zoom: 100%;
    }
  }
  
  @media only screen and (min-width: 2560px) {
    .main {
      zoom: 140%;
    }
  }
  
  @media only screen and (min-width: 3840px) {
    .main {
      zoom: 200%;
    }
  }
  