.main {
    position: relative;
    background-image: url(./images/bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 0;
    font-family: ProximaNovaBold;
  }
  .container {
    width: 81%;
    height: 75%;
    position: relative;
    display: flex;
  }
  .lister {
    display: flex;
    margin-top: 150px;
    flex-wrap: wrap;
  }
  .lister_item {
    width: 356px;
    height: 490px;
    border-radius: 40px;
    margin-right: 31px;
    position: relative;
    margin-top: 20px;
  }
  .img1 {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: url(./images/1.png);
    background-position: center;
    background-size: 220%;
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.1s;
  }
  .img2 {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: url(./images/2.png);
    background-position: center;
    background-size: 280%;
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.1s;
  }
  .img3 {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: url(./images/3.png);
    background-position: center;
    background-size: 310%;
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.1s;
  }
  .img4 {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: url(./images/4.png);
    background-position: center;
    background-size: 280%;
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.1s;
  }
  .img1:hover {
    opacity: 1;
    transition: 0.1s;
  }
  .img2:hover {
    opacity: 1;
    transition: 0.1s;
  }
  .img3:hover {
    opacity: 1;
    transition: 0.1s;
  }
  .img4:hover {
    opacity: 1;
    transition: 0.1s;
  }
  .lister_text_cont {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
  .lister_text1 {
    font-size: 24px;
    color: white;
    opacity: 1 !important;
  }
  .lister_text2 {
    color: #d4d4d4;
    font-size: 16px;
    margin-top: 5px;
    opacity: 1 !important;
  }
  .desc {
    font-size: 30px;
    color: white;
    position: absolute;
    margin-top: 60px;
  }
  
  @media only screen and (min-width: 390px) {
    .lister {
      zoom: 58%;
    }
    .desc {
      zoom: 48%;
    }
    .lister_item {
      zoom: 70%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .lister_item {
      zoom: 60%;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .lister {
      zoom: 68%;
    }
    .desc {
      zoom: 68%;
    }
    .lister_item {
      zoom: 90%;
    }
  }
  
  @media only screen and (min-width: 1366px) {
    .lister {
      zoom: 73%;
    }
    .desc {
      zoom: 73%;
    }
    .lister_item {
      zoom: 100%;
    }
  }
  
  @media only screen and (min-width: 1600px) {
    .lister {
      zoom: 86%;
    }
    .desc {
      zoom: 86%;
    }
    .lister_item {
      zoom: 100%;
    }
  }
  
  @media only screen and (min-width: 1920px) {
    .lister {
      zoom: 100%;
    }
    .desc {
      zoom: 100%;
    }
    .lister_item {
      zoom: 100%;
    }
  }
  
  @media only screen and (min-width: 2560px) {
    .lister {
      zoom: 140%;
    }
    .desc {
      zoom: 140%;
    }
    .lister_item {
      zoom: 90%;
    }
  }
  
  @media only screen and (min-width: 3840px) {
    .lister {
      zoom: 205%;
    }
    .desc {
      zoom: 205%;
    }
    .lister_item {
      zoom: 100%;
    }
  }
  